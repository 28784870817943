import { AccessType, ContentCategory } from '@gn/core/type';
import {
    basedOnInterestsTitle,
    trendingContentTitle,
    ContentConfigItem,
    featuredContentTitle,
    latestContentTitle,
} from './contentConfig';
import { ContentCategoriesTagsIds } from './types';
import { addMonths, addYears } from 'date-fns';

export const freeWorkshopsTitle = 'Free workshops';

export const getHomePageContentConfig: () => ContentConfigItem[] = () => {
    const halfYearAgo = addMonths(new Date(), -6);
    const yearAgo = addYears(new Date(), -1);

    return [
        {
            title: trendingContentTitle,
            customRequest: true,
        },
        {
            title: featuredContentTitle,
            where: {
                featured: true,
                endDate: {
                    gte: yearAgo,
                },
            },
        },
        {
            title: 'Short takes',
            where: {
                duration: {
                    lt: 9 * 60,
                },
                endDate: {
                    gte: yearAgo,
                },
            },
            orderBy: [{ views: 'desc' }],
        },
        {
            title: basedOnInterestsTitle,
            customRequest: true,
        },
        {
            title: latestContentTitle,
            where: {
                endDate: {
                    gte: halfYearAgo,
                },
            },
        },
        {
            title: 'Popular',
            orderBy: [
                {
                    views: 'desc',
                },
            ],
        },
        {
            title: 'JavaScript',
            where: {
                tags: {
                    some: {
                        id: {
                            in: [ContentCategoriesTagsIds.JavaScript],
                        },
                    },
                },
                endDate: {
                    gte: yearAgo,
                },
            },
        },
        {
            title: 'React',
            where: {
                tags: {
                    some: {
                        id: {
                            in: [ContentCategoriesTagsIds.React],
                        },
                    },
                },
                endDate: {
                    gte: yearAgo,
                },
            },
        },
        {
            title: freeWorkshopsTitle,
            where: {
                endDate: {
                    gte: yearAgo,
                },
            },
            orderBy: [
                {
                    endDate: 'desc',
                },
            ],
            categories: [ContentCategory.Workshop],
            accesses: [AccessType.Public, AccessType.Regular],
        },
    ];
};
