import Link from 'next/link';

export const TagHeaderWithLink = () => (
    <div className="aside-block__head">
        <div className="aside-title">tags</div>
        <Link href={'/tags'} className="aside-link">
            Discover all tags
        </Link>
    </div>
);
