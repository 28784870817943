import { TagHeaderWithLink } from './TagHeaderWithLink';
import { TagComponent } from './TagComponent';
import React from 'react';
import { NewContentCountResult } from '../../../contents/queries/countUserNewContent';
import { useNewContentCounter } from '../../hooks/useNewContentCounter';

const MENU_TAGS_COUNT = 10;

export const MobileTagsList = ({ tags, currentUser }) => {
    const { newContentByTagsIdCounts } = useNewContentCounter(currentUser) as NewContentCountResult;
    const takeFirstRequiredAmount = (array) => {
        return array.slice(0, MENU_TAGS_COUNT);
    };
    let menuTags = takeFirstRequiredAmount(tags);

    if (currentUser && currentUser.interests?.length) {
        if (currentUser.interests.length > MENU_TAGS_COUNT) {
            menuTags = takeFirstRequiredAmount(currentUser.interests);
        } else {
            const difference = MENU_TAGS_COUNT - currentUser.interests.length;
            menuTags = [...currentUser.interests, ...tags.slice(0, difference)];
        }
    }

    return (
        <div className="aside-block visible-md">
            <TagHeaderWithLink />
            <div className="tags-list">
                {menuTags.map((tag, index) => (
                    <TagComponent
                        key={`${tag.id}-${index}`}
                        selectedTag={null}
                        tag={tag}
                        prefetch={true}
                        userNewContentTags={newContentByTagsIdCounts}
                    />
                ))}
            </div>
        </div>
    );
};
